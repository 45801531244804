import CobrandManagement from './CobrandManagement';
import CustomerManagement from './CustomerManagement';
import MerchantPortal from './MerchantPortal';
import PriorityPass from './PriorityPass';
import Reward from './Reward';
import tcsp from './tcsp';
import Transaction from './Transaction';

const APIs = {
  CustomerManagement,
  tcsp,
  Transaction,
  MerchantPortal,
  CobrandManagement,
  Reward,
  PriorityPass,
};

export default APIs;
