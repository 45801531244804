import { ReferralCode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

export function searchReferralCodeProperty<T>(applicablePrograms: any, programName: string, targetReferralCode: string, propertyName: keyof ReferralCode): T | null {
    if (!applicablePrograms) return null;

    for (const program of applicablePrograms) {
      if (program.programName === programName) {
        if (program.referralCodes && program.referralCodes.length > 0) {
          for (const referralCodeObj of program.referralCodes) {
            if (referralCodeObj.referralCode === targetReferralCode) {
              const value = referralCodeObj[propertyName];
              return value !== undefined && value !== null ? value : null;
            }
          }
        }
        return null;
      }
    }
    return null;
  }