import { useMemo, useState } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { CustomPagination, NoRowsOverlay } from '../../../components/Layout';
import { useTranslation } from '../../../hooks';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults, tableConfig } from '../../../utils/constant';
import { useZusDialogStore } from '../../../zustand/store';
import { IvisitHistoryApiRes, translateKeyObj as TK, translatePrefix } from './config';

const PAGE_SIZE = tableConfig.pageSize;

export default function DialogVisitHistory() {
  const [page, setPage] = useState(0);
  const { t, tc } = useTranslation(translatePrefix);

  const zusDialog = useZusDialogStore();

  const visitHistoryArr = zusDialog.meta?.visitHistory as IvisitHistoryApiRes | undefined;

  const totalCount = visitHistoryArr?.length;

  const rows = useMemo(() => {
    if (!visitHistoryArr) {
      return [];
    }

    const sliceFirstIndex = page * PAGE_SIZE;

    const sliceLastIndex = sliceFirstIndex + PAGE_SIZE;

    return visitHistoryArr.slice(sliceFirstIndex, sliceLastIndex);
  }, [visitHistoryArr, page]);

  const handleCloseDialog = async () => {
    await zusDialog.close();
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.visitDate, {
      minWidth: 150,
    }),
    useGenGridCol(TK.itemType, {
      minWidth: 150,
    }),
    useGenGridCol(TK.outletName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.locationName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.city, {
      minWidth: 150,
    }),
    useGenGridCol(TK.country, {
      minWidth: 150,
    }),
  ];

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.visitHistory),
    self: {
      open: zusDialog.match('visitHistoryDialog'),
      onClose: handleCloseDialog,
    },
    content: (
      <DataGrid
        {...dataGridDefaults}
        rows={rows}
        rowCount={totalCount}
        columns={columns}
        page={page}
        onPageChange={(page) => setPage(page)}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay customWording={t(TK.noVisitHistoryRecord)} />,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: totalCount },
        }}
      />
    ),
    onCancel: handleCloseDialog,
    onConfirm: () => {},
    isConfirmHidden: true,
    cancelButtonText: tc('close'),
  };

  return <DialogInOne {...dialogConfig} />;
}
