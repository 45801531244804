import { useTranslate } from 'react-admin';

import Stack from '@mui/material/Stack';

import { COLOR_THEME } from '../../style/colorTheme';
import { hexToRGB } from '../../utils';

export default function NoRowsOverlay({ customWording }: { customWording?: string }) {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: `rgba(${hexToRGB(COLOR_THEME.Layout.NoRowsOverlay.Background)}, 0.5)`,
        color: COLOR_THEME.Layout.NoRowsOverlay.Text,
      }}
    >
      {customWording || t('noRecords')}
    </Stack>
  );
}
