import { useTranslate } from 'react-admin';

import en from '../components/i18nProvider/en';

export type Iprefix = keyof typeof en;
export type IcommonKey = keyof typeof en.common;
export type IenumKey = keyof typeof en.enumConstants;
export type IcountryCodeKey = keyof typeof en.countryCodes;
export default function useTranslation(prefix?: Iprefix) {
  const translate = useTranslate();
  const tc = (key: IcommonKey, params = {}) => translate(`common.${key}`, params);

  const displayExistEnumOnly = (key: string, params = {}) => {
    const notAssignedOrNotFoundKey = key ? `{{${key}}}` : '';
    return Object.keys(en.enumConstants).includes(key)
      ? translate(`enumConstants.${key}`, params)
      : notAssignedOrNotFoundKey;
  };

  const genricTranslate = (key: string, params = {}) =>
    prefix === 'enumConstants'
      ? displayExistEnumOnly(key, params)
      : translate(`${prefix}.${key}`, params);

  const t = (key: string, params = {}) => genricTranslate(key, params);
  const te = (key: IenumKey, params = {}) => displayExistEnumOnly(key, params);

  const tb = (expectedBol: unknown) => {
    if (expectedBol === true) {
      return te('yes');
    }
    if (expectedBol === false) {
      return te('no');
    }
    return '';
  };

  const tqt = (key: string, params = {}) => translate(`questionType.${key}`, params);

  const tcc = (countryCode: string) =>
    en.countryCodes.find(({ isoCode3 }) => countryCode === isoCode3)?.country || '';

  return { translate, t, tc, te, tcc, tb, tqt };
}
