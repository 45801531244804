import { PriorityPass } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { Ipage } from '../../../api/types';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

export const translatePrefix = 'membership';

type ApiParam = Ipage & Partial<IsearchParam>;

export const useZusParams = createZusInstance<ApiParam>(initZusParams);

export type Ifields = {
  membershipNumber: string;
  programNames: string[];
  customerNumber: string;
  panLast4: string;
  status: string;
};

export type IsearchParam = {
  membershipNumber: string;
  programNames: string[];
  customerNumber: string;
  panLast4: string;
  status: string;
};

export const initFields = {
  membershipNumber: '',
  programNames: [],
  customerNumber: '',
  panLast4: '',
  status: '',
};

export { initZusParams };

const tableTranslateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  membershipNumber: 'membership_no',
  programName: 'program_name',
  customerNumber: 'customer_no',
  panLast4: 'pan_last4',
  status: 'status',
  operation: 'operation',
  cardNumber: 'card_no',
  enrolmentDetails: 'enrolment_details',
  entitilementDetails: 'entitilement_details',
  visitHistory: 'visit_history',
};

const entitlementDetailsTranslateKeyObj = {
  totalEntitlement: 'total_entitlement',
  pending: 'pending',
  used: 'used',
  remaining: 'remaining',
};
const visitHistoryTranslateKeyObj = {
  visitDate: 'visit_date',
  itemType: 'item_type',
  outletName: 'outlet_name',
  locationName: 'location_name',
  city: 'city',
  country: 'country',
  noVisitHistoryRecord: 'no_visit_history_record',
};

export const translateKeyObj = {
  ...tableTranslateKeyObj,
  ...entitlementDetailsTranslateKeyObj,
  ...visitHistoryTranslateKeyObj,
};

export const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

export const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  entitlementDetails: (params: { membershipNumber: string }, config?: any) => Promise<any>;
  visitHistory: (params: { membershipNumber: string }, config?: any) => Promise<any>;
} = Object.freeze({
  export: api.PriorityPass.membership.getExport,
  table: api.PriorityPass.membership.getAll,
  entitlementDetails: api.PriorityPass.membership.getEntitlementDetails,
  visitHistory: api.PriorityPass.membership.getVisitHistory,
});

export const exportFileName = 'Membership';

export const PermissionKey = PriorityPass.Membership;

export type ItableApiRes = {
  id: string;
  merchantId: string;
  programName: string;
  customerNumber: string;
  cardNumber: string;
  panLast4: string;
  membershipNumber: string;
  brand: string;
  dealId: string;
  enrollDetails: string;
  status: number;
  createdBy: string;
  lastModifiedBy: string;
  createdDate: string;
  lastModifiedDate: string;
  customerId: string;
  expiredDate: string;
};

export type IentitlementDetails = {
  Category: string;
  FreeGuestsPerExperience: number;
  SharedMemberGuestAllowance: boolean;
  MemberEntitlements: {
    _type: string;
    Total: number;
    Used: number;
    Pending: number;
    Remaining: number;
    ChargeFee: number;
    ChargeCurrency: string;
  };
  GuestEntitlements: {
    _type: string;
    Total: number;
    Used: number;
    Pending: number;
    Remaining: number;
    ChargeFee: number;
    ChargeCurrency: string;
  };
};

export type IentitlementDetailsApiRes = IentitlementDetails[];

export type IvisitHistory = {
  ItemType: string;
  OutletCode: string;
  OutletName: string;
  Location: {
    LocationType: string;
    LocationCode: string;
    LocationName: string;
    City: string;
    Country: string;
    CountryCode: string;
    Latitude: number;
    Longitude: number;
    TerminalCode: number;
    Terminal: string;
    Direction: string;
  };
  Media: {
    Width: number;
    Height: number;
    MimeType: string;
    URL: string;
    Description: string;
    LastModified: string;
  };
  VisitDate: string;
  TotalVisitors: number;
  TotalGuests: number;
  VisitReference: string;
  NonMemberChargeCount: number;
  MemberChargeCount: number;
  MemberChargeCurrency: string;
  MemberChargeFee: number;
  MemberChargeDeclined: boolean;
  ProcessedDate: string;
};

export type IvisitHistoryApiRes = IvisitHistory[];
