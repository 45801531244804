import { ChangeEvent, useState } from 'react';

import {
  EnumIdvMethod,
  EnumReferralCodeStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { MpTextField, SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { Button, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { usePermission, useTranslation } from '../../../hooks';
import { useGetProgram } from '../../../hooks/useProgram';
import { useZusDialogStore } from '../../../zustand/store';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogCreateRecord from './DialogCreateRecord';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { EnumAllProgram } = useGetProgram();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const onChange =
    (field: keyof typeof fields) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setFields((fields) => ({ ...fields, [field]: e.target.value }));
      };

  const getParams: () => IsearchParam = () => {
    const { idvMethod: kycIdvMethod, programName: programNames, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    return {
      ...initZusParams,
      ...rest,
      kycIdvMethod,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) => setFields((fields) => ({ ...fields, programName: value }))}
          onClear={() => setFields((f) => ({ ...f, programName: [] }))}
        />
      ),
    },
    {
      labelKey: TK.programAgentId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.programAgentId) })}
          value={fields.programAgentId}
          onChange={onChange('programAgentId')}
        />
      ),
    },
    {
      labelKey: TK.distributorAgentId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.distributorAgentId) })}
          value={fields.distributorAgentId}
          onChange={onChange('distributorAgentId')}
        />
      ),
    },
    {
      labelKey: TK.referralCode,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.referralCode) })}
          value={fields.referralCode}
          onChange={onChange('referralCode')}
        />
      ),
    },
    {
      labelKey: TK.status,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.status) })}
          value={fields.status}
          onChange={onChange('status')}
          enumData={EnumReferralCodeStatus}
          clearSelect={() => setFields((f) => ({ ...f, status: '' }))}
        />
      ),
    },
    {
      labelKey: TK.idvMethod,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.idvMethod) })}
          value={fields.idvMethod}
          onChange={onChange('idvMethod')}
          enumData={EnumIdvMethod}
          clearSelect={() => setFields((f) => ({ ...f, idvMethod: '' }))}
        />
      ),
    },
  ];

  const openCreateRecordDialog = () => zusDialog.open('createRecordDialog');

  return (
    <Container disableGutters maxWidth={false}>
      <>
        <DialogCreateRecord />
        <FilterSectionActionRow>
          {hasPermission(PermissionKey.CreateRecord) && (
            <Button variant="outlined" onClick={openCreateRecordDialog}>
              {t(TK.createRecord)}
            </Button>
          )}

          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>
      </>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
