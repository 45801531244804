import EptPriorityPass from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal/EptPriorityPass';

import axios from '../axiosInstance';

const endPoint = EptPriorityPass.membership;

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.getAll, input);
}
async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(endPoint.export, input);
}
async function getEntitlementDetails(params: { membershipNumber: string }, config?: any) {
  const input = {
    params,
    ...config,
  };

  return await axios.get(endPoint.entitlementDetails, input);
}
async function getVisitHistory(params: { membershipNumber: string }, config?: any) {
  const input = {
    params,
    ...config,
  };

  return await axios.get(endPoint.visitHistory, input);
}

export default { getAll, getExport, getEntitlementDetails, getVisitHistory };
