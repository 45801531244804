import { useLayoutEffect, useMemo, useState } from 'react';

import { EnumCustomerApplicationType, ReferralCode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../api';
import { DialogInOne, SingleSelection } from '../../../components';
import AutoComplete from '../../../components/AutoComplete';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box } from '../../../components/MuiGenerals';
import CustomerLevelSingleSelection from '../../../features/common/filterTable/components/Filters/CustomerLevelSingleSelection';
import { useAlerting, useTranslation } from '../../../hooks';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { IenumKey } from '../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../zustand/store';
import { IapplicableProgram } from '../CustomerApplication/config';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';
import program from '../../../api/CobrandManagement/program';
import { searchReferralCodeProperty } from '../../../helper/getReferralCodeProperty';

const initFields = {
  customerNumber: '',
  customerLevel: '',
  programName: '',
  referralCode: '',
};

export default function DialogAssignCustomerNumber() {
  const { t, tc, te } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();

  const { alerting } = useAlerting();

  const [customerNumberOptions, setCustomerNumberOptions] = useState<Array<any>>([]);

  const [fields, setFields] = useState(initFields);
  const [applicablePrograms, setApplicablePrograms] = useState<IapplicableProgram[]>();

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { applicationNumber, type, firstName, lastName, phoneCountryCode, phoneNumber, programAgentId, distributorAgentId } =
    zusDialog.meta;

  const displayCustomerName = firstName + ' ' + lastName;
  const displayPhoneNumber = phoneCountryCode + ' ' + phoneNumber;
  const displayProgramAgentId = searchReferralCodeProperty<string | null>(applicablePrograms ,fields.programName, fields.referralCode, 'programAgentId')
  const displayDistributorAgentId = searchReferralCodeProperty<string | null>(applicablePrograms, fields.programName, fields.referralCode, 'distributorAgentId')
  
  useLayoutEffect(() => {
    // sync display data to local state
    if (!zusDialog.meta) {
      return;
    }

    const {
      customerNumber = '',
      customerLevel = '',
      programName = '',
      referralCode = '',
      applicablePrograms = [],
    } = zusDialog.meta || {};

    setFields((prev) => ({ ...prev, customerNumber, customerLevel, programName, referralCode }));
    setApplicablePrograms(applicablePrograms);
  }, [zusDialog.meta]);

  const handleCustomerLevelOnChange = async (value: string) => {
    const customerLevelInputValue = value;

    setFields((prev) => ({ ...prev, customerLevel: customerLevelInputValue }));
  };

  const partialSearchCustomerNumber = async ({
    customerNumber,
    programName,
  }: {
    customerNumber: string;
    programName: string;
  }) => {
    if (!customerNumber || !programName) return;

    const res = await APIs.MerchantPortal.customer.partialSearchCustomerNumber({
      customerNumber,
      programName,
    });

    if (!res) {
      return [];
    }

    return res;
  };

  const getCustomerNumberOptions = (
    data: { merchantId: number; programName: string; customerNumber: string }[]
  ) => {
    // convert to <AutoComplete> options
    const customerNumberOptions = data.map((item) => item.customerNumber);

    return customerNumberOptions;
  };

  const handleCustomerNumberAutoCompleteOnChange = async (value: string) => {
    setFields((prev) => ({ ...prev, customerNumber: value }));

    if (value.length >= 4) {
      const customerNumberSearchResult =
        (await partialSearchCustomerNumber({
          customerNumber: value,
          programName: fields.programName,
        })) || [];

      const customerNumberOptions = getCustomerNumberOptions(customerNumberSearchResult);

      setCustomerNumberOptions(customerNumberOptions);
    }
  };

  const EnumApplicableProgramNames = useMemo(
    () =>
      applicablePrograms?.reduce(
        (acc: Record<string, string>, { programName }) => ({
          ...acc,
          [programName]: programName,
        }),
        {}
      ) || {},
    [applicablePrograms]
  );

  const EnumApplicableReferralCode = useMemo(
    () =>
      applicablePrograms
        ?.find(({ programName }) => fields.programName === programName)
        ?.referralCodes?.reduce(
          (acc, { referralCode }) => ({ ...acc, [referralCode]: referralCode }),
          {}
        ) || {},
    [fields.programName]
  );

  const labelElePairArr = [
    [TK.applicationNumber, <Box>{applicationNumber}</Box>],
    [TK.applicationType, <Box>{te(EnumCustomerApplicationType[type] as IenumKey)}</Box>],
    [
      TK.programName,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.programName) })}
        value={fields.programName}
        onChange={(e) =>
          setFields((prev) => ({
            ...prev,
            programName: e.target.value,
            referralCode: '',
            customerNumber: '',
            customerLevel: '',
          }))
        }
        enumData={EnumApplicableProgramNames}
        clearSelect={() => { }}
        nameFn={(name) => name}
      />,
    ],
    [
      TK.referralCode,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.referralCode) })}
        value={fields.referralCode}
        onChange={(e) => setFields((prev) => ({ ...prev, referralCode: e.target.value }))}
        enumData={EnumApplicableReferralCode}
        clearSelect={() => { }}
        nameFn={(name) => name}
      />,
    ],
    [TK.programAgentId, <Box>{displayProgramAgentId}</Box>],
    [TK.distributorAgentId, <Box>{displayDistributorAgentId}</Box>],
    [TK.customerName, <Box>{displayCustomerName}</Box>],
    [TK.phoneNumber, <Box>{displayPhoneNumber}</Box>],
    [
      TK.customerNumber,
      // <MpTextField
      //   label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
      //   value={fields.customerNumber}
      //   onChange={(e) => setFields((prev) => ({ ...prev, customerNumber: e.target.value }))}
      // />,
      <AutoComplete
        disabled={!fields.programName}
        label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
        value={fields.customerNumber}
        onInputChange={(_, value) => handleCustomerNumberAutoCompleteOnChange(value)}
        options={customerNumberOptions}
        setOptions={setCustomerNumberOptions}
        noOptionText={tc('no_relevant_record')}
      />,
    ],
    [
      TK.customerLevel,
      <Box sx={{ position: 'relative', '>div': { width: '100%' } }}>
        <CustomerLevelSingleSelection
          showNoCustomerLevelFoundAlert
          handleNonExistInCustomerLevelListValue
          showOptionsWithRewardRate
          programName={fields.programName}
          label={tc('phSelection', { fieldName: t(TK.customerLevel) })}
          value={fields.customerLevel}
          onChange={(e) => handleCustomerLevelOnChange(e.target.value)}
          onClear={() => setFields((prev) => ({ ...prev, customerLevel: '' }))}
        />
      </Box>,
    ],
  ] as Array<[string, JSX.Element]>;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
    setApplicablePrograms(undefined);
  };

  const handleSubmit = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr);
    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const res = await api.assignCustomerNumber({
      applicationNumber,
      ...fields,
    });

    if (!res) {
      return;
    }

    handleCloseDialog();
    alerting('success', t(TK.successAssignCustomerNumber));
    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.assignCustomerNumber),
    self: {
      open: zusDialog.match('assignCustomerNumberDialog'),
      onClose: handleCloseDialog,
    },
    size: 'sm',
    content: (
      <GridBox
        columnCount={1}
        labelElePairArr={labelElePairArr}
        containerSx={{ paddingBottom: '48px' }}
      />
    ),
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,
    dialogActionStyling: { padding: '16px' },
  };

  // if (!isOpen) {
  //   return <></>;
  // }

  return <DialogInOne {...dialogConfig} />;
}
