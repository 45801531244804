import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import { translateKeyObj as TK, translatePrefix } from './config';

export default function DialogEnrolmentDetails() {
  const { t, tc } = useTranslation(translatePrefix);

  const zusDialog = useZusDialogStore();

  const { enrolmentDetails } = zusDialog.meta;

  const handleCloseDialog = async () => {
    await zusDialog.close();
  };

  const dialogConfig: IdialogInOneProps = {
    size: 'sm',
    title: t(TK.enrolmentDetails),
    self: {
      open: zusDialog.match('enrolmentDetailsDialog'),
      onClose: handleCloseDialog,
    },
    content: (
      <div
        style={{
          whiteSpace: 'pre-wrap',
          lineHeight: '1.5',
          fontFamily: 'monospace',
          fontSize: '16px',
          padding: '10px',
          overflowX: 'auto',
        }}
      >
        {JSON.stringify(enrolmentDetails, null, 2)}
      </div>
    ),
    onConfirm: () => {},
    onCancel: handleCloseDialog,
    isConfirmHidden: true,
    cancelButtonText: tc('close'),
  };

  return <DialogInOne {...dialogConfig} />;
}
