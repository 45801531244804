import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp
} from '@mui/x-data-grid';
import {
  EnumCustomerApplicationStatus,
  EnumCustomerApplicationType,
  EnumIdvMethod,
  EnumIdvStatus,
  EnumQuestionType
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../api';
import { OpRedirect } from '../../../assets/icons';
import { LoadingDialog } from '../../../components';
import { ExportBtn, OpIconButton } from '../../../components/Button';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import {
  CustomPagination,
  NoRowsOverlay,
  TableTab
} from '../../../components/Layout';
import { Box, Container, Tooltip } from '../../../components/MuiGenerals';
import { displayAmountWithCurrency } from '../../../helper';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation
} from '../../../hooks';
import { useGetProgram } from '../../../hooks/useProgram';
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime
} from '../../../utils';
import {
  dataGridColumnConfig,
  useGenGridCol
} from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  EnumOperationDialogMode,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';
import DialogApprovalProgress from './DialogApprovalProgress';
import DialogAssignCustomerNumber from './DialogAssignCustomerNumber';
import DialogOperation from './DialogOperation';
import DialogReferenceCode from './DialogReferenceCode';
import DialogSwitchManualReview from './DialogSwitchManualReview';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

const holdApplicationApiFunc = APIs.CustomerManagement.customerApplication.holdApplication;
const releaseApplicationHoldApiFunc =
  APIs.CustomerManagement.customerApplication.releaseApplicationHold;

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();

  const { hasPermission, hasPermissionMultipleAll, hasPermissionMultipleEither } = usePermission();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const operationCell = (params: GridRenderCellParams<any, any, any>) => {
    const { id: customerApplicationId, ...rawData } = params.row.rawData;

    const { status, kycIdvStatus, type } = rawData;

    const isPending = status === EnumCustomerApplicationStatus.Pending;
    const isOnHold = status === EnumCustomerApplicationStatus.onHold;
    const isManualReview = kycIdvStatus === EnumIdvStatus.ManualReview;
    const isManualReviewIdvDisabled = isOnHold || !isManualReview;

    const isApprovable =
      isPending &&
      (kycIdvStatus === EnumIdvStatus.Success ||
        kycIdvStatus === EnumIdvStatus.ManualSuccess ||
        kycIdvStatus === EnumIdvStatus.Verified ||
        kycIdvStatus === EnumIdvStatus.Skipped);

    const isManualReviewSwitchable =
      !isOnHold &&
      (kycIdvStatus === EnumIdvStatus.None ||
        kycIdvStatus === EnumIdvStatus.Retry ||
        kycIdvStatus === EnumIdvStatus.Pending);

    const getReleaseOrOnHoldMenuTitle = () => {
      const onHoldMenuTitle = t('onHoldMenuTitle');
      const releaseHoldMenuTitle = t('releaseHoldMenuTitle');

      if (isOnHold) return releaseHoldMenuTitle;

      return onHoldMenuTitle;
    };

    const getIsReleaseOrOnHoldMenuDisabled = () => {
      if (isPending || isOnHold) return false;

      return true;
    };

    const releaseOrOnHoldMenuTitle = getReleaseOrOnHoldMenuTitle();

    const isReleaseOrOnHoldMenuDisabled = getIsReleaseOrOnHoldMenuDisabled();

    const isUpdateInfo = type === EnumCustomerApplicationType.UpdateInfo;

    const shouldDisableAssignCustomerNumber =
      !isApprovable ||
      type === EnumCustomerApplicationType.UpdateInfo ||
      type === EnumCustomerApplicationType.UpdatePhoneNo ||
      type === EnumCustomerApplicationType.UpdateEmailAddress;

    const handleOpenOperationDialog = (dialogMode: EnumOperationDialogMode) => () => {
      zusDialog.open('operationDialog', {
        customerApplicationId,
        dialogMode,
        ...rawData,
      });
    };

    const handleOpenSwitchManualReviewDialog = () => {
      zusDialog.open('manualReviewSwitchDialog', {
        customerApplicationId,
        ...rawData,
      });
    };

    const handleAssignCustomerNumberDialog = async () => {
      const { customerId } = rawData;
      const applicablePrograms = await api.applicableProgram({
        customerId,
        includePending: true,
      });

      if (!applicablePrograms) {
        return;
      }

      zusDialog.open('assignCustomerNumberDialog', {
        customerApplicationId,
        ...rawData,
        applicablePrograms,
      });
    };

    const getHoldOrReleaseDetails = () => {
      let apiFunc;
      let successMsg = '';

      if (isOnHold) {
        apiFunc = releaseApplicationHoldApiFunc;
        successMsg = t('releaseApplicationSuccessAlert');
      } else if (isPending) {
        apiFunc = holdApplicationApiFunc;
        successMsg = t('onHoldApplicationSuccessAlert');
      }

      return { apiFunc, successMsg };
    };

    const handleReleaseOrOnHold = async () => {
      const { apiFunc, successMsg } = getHoldOrReleaseDetails();

      if (!apiFunc) return;

      zusDialog.openExtra('loadingDialog');

      // api call
      const res = await apiFunc({ applicationNumber: rawData.applicationNumber });

      if (!res) {
        return zusDialog.closeExtra();
      }

      zusDialog.closeExtra();
      alerting('success', successMsg);

      // refresh table
      zusParams.refetch();
    };

    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        <ButtonMenu
          title={t(TK.operation)}
          options={[
            {
              name: t(TK.details),
              onClickFn: handleOpenOperationDialog(EnumOperationDialogMode.details),
              noShow: !hasPermission(PermissionKey.Details.prefix),
            },
            {
              name: releaseOrOnHoldMenuTitle,
              onClickFn: handleReleaseOrOnHold,
              noShow: !hasPermission(PermissionKey.OnHoldOrReleaseHold),
              isDisabled: isReleaseOrOnHoldMenuDisabled || isUpdateInfo,
            },
            {
              name: t(TK.switchToManualReview),
              onClickFn: handleOpenSwitchManualReviewDialog,
              noShow: !hasPermission(PermissionKey.SwitchToManualReview),
              isDisabled: !isManualReviewSwitchable || isUpdateInfo,
            },
            {
              name: t(TK.manualVerifyIdv),
              onClickFn: handleOpenOperationDialog(EnumOperationDialogMode.manualVerifyIdv),
              noShow: !hasPermission(PermissionKey.Details.ManualReviewIdv.prefix),
              isDisabled: isManualReviewIdvDisabled || isUpdateInfo,
            },
            {
              name: t(TK.assignCustomerNumber),
              onClickFn: handleAssignCustomerNumberDialog,
              noShow: !hasPermission(PermissionKey.AssignCustomerNumber),
              isDisabled: shouldDisableAssignCustomerNumber,
            },
            {
              name: tc('approve_reject_request_btn'),
              onClickFn: handleOpenOperationDialog(EnumOperationDialogMode.approval),
              noShow: !hasPermissionMultipleEither([
                PermissionKey.Details.Reject,
                PermissionKey.Details.Approve.prefix,
              ]),
              isDisabled: !isApprovable,
            },
          ]}
        />
      </Box>
    );
  };

  const approvalProgressCell = (params: GridRenderCellParams<any, any, any>) => {
    const approvalProgress = params.row[TK.approvalProgress];
    const isAutoApprove = approvalProgress === tc('auto');

    const { customer_application_approvals } = params.row.rawData;

    if (!approvalProgress) {
      return <></>;
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Tooltip title={approvalProgress}>
          <Box>{approvalProgress}</Box>
        </Tooltip>

        {!isAutoApprove && (
          <OpIconButton
            size={'1.2rem'}
            title={''}
            svgUrl={OpRedirect}
            onClick={() =>
              zusDialog.open('approvalProgressDialog', { customer_application_approvals })
            }
            sxBox={{ marginLeft: '5px' }}
          />
        )}
      </Box>
    );
  };

  const kycReferenceCodeCell = (params: GridRenderCellParams<any, any, any>) => {
    const kycReferenceCode = params.row[TK.kycReferenceCode];

    const { kycRef = { workflowRunId: '', applicantId: '' } } = params.row.rawData as ItableApiRes;

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Tooltip title={kycReferenceCode}>
          <Box
            sx={{
              marginRight: '4px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              minWidth: 'auto',
              maxWidth: '70%',
            }}
          >
            {kycReferenceCode}
          </Box>
        </Tooltip>

        <OpIconButton
          size={'1.2rem'}
          title={''}
          svgUrl={OpRedirect}
          onClick={() =>
            zusDialog.open('referenceCodeDialog', {
              ...kycRef,
            })
          }
          isDisabled={!kycRef?.applicantId && !kycRef?.workflowRunId}
        />
      </Box>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      minWidth: 100,
      renderCell: operationCell,
    }),
    useGenGridCol(TK.applicationNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.approvalProgress, {
      minWidth: 120,
      renderCell: approvalProgressCell,
    }),
    useGenGridCol(TK.applicationStatus, {
      minWidth: 128,
    }),
    useGenGridCol(TK.idvStatus, {
      minWidth: 128,
    }),
    useGenGridCol(TK.idvMethod, {
      minWidth: 128,
    }),
    useGenGridCol(TK.applicationType),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.distributorAgentId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerLevel, {
      minWidth: 150,
    }),
    useGenGridCol(TK.referralCode, { minWidth: 150 }),
    useGenGridCol(TK.questionType, { minWidth: 200 }),
    useGenGridCol(TK.maxCreditLimit, { minWidth: 150 }),
    useGenGridCol(TK.creditLimit, { minWidth: 150 }),
    useGenGridCol(TK.customerId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.emailAddress, {
      minWidth: 150,
    }),
    useGenGridCol(TK.phoneNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.versionNumber),
    useGenGridCol(TK.latestVersion),
    useGenGridCol(TK.kycReferenceCode, { renderCell: kycReferenceCodeCell }),
    useGenGridCol(TK.idvManuallyVerifiedBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.documentStatus, {
      minWidth: 150,
    }),
    useGenGridCol(TK.documentSubStatus, {
      minWidth: 150,
    }),
    useGenGridCol(TK.facialSimilarityStatus, {
      minWidth: 150,
    }),
    useGenGridCol(TK.facialSimilaritySubStatus, {
      minWidth: 150,
    }),
    useGenGridCol(TK.watchListStatus, {
      minWidth: 150,
    }),
    useGenGridCol(TK.watchListSubStatus, {
      minWidth: 150,
    }),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const hasOperationColPermission = hasPermissionMultipleEither([
    PermissionKey.Details.Approve.prefix,
    PermissionKey.Details.Reject,
    PermissionKey.Details.prefix,
    PermissionKey.AssignCustomerNumber,
    PermissionKey.SwitchToManualReview,
    PermissionKey.Details.ManualReviewIdv.prefix,
  ]);

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasOperationColPermission,
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogOperation />
      <DialogAssignCustomerNumber />
      <DialogReferenceCode />
      <DialogSwitchManualReview />
      <DialogApprovalProgress />
      <DataGrid
        {...dataGridDefaults}
        initialState={dataGridColumnConfig(visibilityConfigArr)}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc, tqt } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);
  const { EnumProgramCurrency } = useGetProgram();

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes & { customerLevel: string }) => {
      const displayCountryCode = /* (item.phoneCountryCode ? '+' : '') + */ item.phoneCountryCode;
      const displayPhoneNumber = displayCountryCode + ' ' + item.phoneNumber;

      const displayCustomerName = item.firstName + ' ' + item.lastName;

      const isAutoApprove =
        Number(item.approvalsRequired) === 0 ||
        (Number(item.approvalsRequired) === 1 &&
          item.customer_application_approvals[0].approvalPermission ===
          PermissionKey.Details.Approve.AutoApproval);

      const invalidApprovalProgressVal =
        isNil(item.approvalsRequired) || isNil(item.approvalsCount);

      const approvalProgressOrNoShow = !invalidApprovalProgressVal
        ? item.approvalsCount + '/' + item.approvalsRequired
        : '';

      const displayApprovalProgress =
        isAutoApprove && item.status === EnumCustomerApplicationStatus.Approved
          ? tc('auto')
          : approvalProgressOrNoShow;

      const displayAmount = displayAmountCurrying(0, 2);

      const currency = EnumProgramCurrency[item.programName];

      const mappedResult = [
        ['rawData', item],
        [TK.applicationNumber, item.applicationNumber],
        [TK.approvalProgress, displayApprovalProgress],
        [TK.applicationStatus, t(EnumCustomerApplicationStatus[item.status])],
        [TK.idvStatus, t(EnumIdvStatus[item.kycIdvStatus])],
        [TK.idvMethod, t(EnumIdvMethod[item.kycIdvMethod])],
        [TK.applicationType, t(EnumCustomerApplicationType[item.type])],
        [TK.programName, item.programName],
        [TK.programAgentId, item.programAgentId],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.customerNumber, item.customerNumber],
        [TK.customerLevel, item.customerLevel],
        [TK.referralCode, item.referralCode],
        [TK.questionType, tqt(EnumQuestionType[Number(item.applicationReferralCode?.questionType)])],
        [
          TK.maxCreditLimit,
          displayAmountWithCurrency(displayAmount(item.maxCreditLimit), currency),
        ],
        [TK.creditLimit, displayAmountWithCurrency(displayAmount(item.creditLimit), currency)],
        [TK.customerId, item.customerId],
        [TK.customerName, displayCustomerName],
        [TK.emailAddress, item.email],
        [TK.phoneNumber, displayPhoneNumber],
        [TK.versionNumber, item.version],
        [TK.latestVersion, item.isLatestVersion ? t('yes') : t('no')],
        // [TK.kycReferenceCode /* item.kycRef */],
        [TK.idvManuallyVerifiedBy, item.kycIdvManuallyVerifiedBy],
        [TK.documentStatus, item.kycDocumentStatus],
        [TK.documentSubStatus, item.kycDocumentSubStatus],
        [TK.facialSimilarityStatus, item.kycFacialStatus],
        [TK.facialSimilaritySubStatus, item.kycFacialSubStatus],
        [TK.watchListStatus, item.kycWatchListStatus],
        [TK.watchListSubStatus, item.kycWatchListSubStatus],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
