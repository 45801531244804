import { ChangeEvent, useState } from 'react';

import {
  EnumIdvMethod,
  EnumQuestionType,
  EnumReferralCodeStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne, MpTextField, SingleSelection } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import { useAlerting, useTranslation } from '../../../hooks';
import useAssets from '../../../hooks/useAssets/useAssets';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useGetProgram } from '../../../hooks/useProgram';
import { useZusDialogStore } from '../../../zustand/store';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';
import QuestionTypeSingleSelection from '../../../features/tcsp/tcspAccountApplication/components/CreateApplicationDialog/components/QuestionTypeSingleSelection';

interface Ifields {
  programName: string;
  referralCode: string;
  idvMethod: string;
  questionType: string;
}

const initFields: Ifields = {
  programName: '',
  referralCode: '',
  idvMethod: '',
  questionType: '',
};

export default function DialogCreateRecord() {
  const zusDialog = useZusDialogStore();

  const { t, te, tc } = useTranslation(translatePrefix);

  const zusParams = useZusParams();
  const { AllAssetCurrenciesEnum } = useAssets();

  const [fields, setFields] = useState(initFields);

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { alerting } = useAlerting();

  const { EnumAllProgram } = useGetProgram();

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.programName,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.programName) })}
        value={fields.programName}
        onChange={onChange('programName')}
        enumData={EnumAllProgram}
        clearSelect={() => {}}
        nameFn={(name) => name}
      />,
    ],
    [
      TK.referralCode,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.referralCode) })}
        value={fields.referralCode}
        onChange={onChange('referralCode')}
      />,
    ],
    [
      TK.idvMethod,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.idvMethod) })}
        value={fields.idvMethod}
        onChange={onChange('idvMethod')}
        enumData={EnumIdvMethod}
        clearSelect={() => {}}
        nameFn={(name) => name}
      />,
    ],
    [
      TK.questionType,
      <QuestionTypeSingleSelection
        label={tc('phSelection', { fieldName: t(TK.questionType) })}
        value={fields.questionType}
        onChange={onChange('questionType')}
      />,
    ]
  ];

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const handleSubmit = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr);
    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const { idvMethod, questionType, ...rest } = fields;

    const status = EnumReferralCodeStatus.Enable;

    const res = await api.createRecord({
      kycIdvMethod: Number(idvMethod),
      questionType: Number(questionType),
      ...rest,
      status,
    });

    if (!res) {
      return;
    }

    await handleCloseDialog();
    zusParams.refetch();
    alerting('success', tc('create_record_success'));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_record'),
    self: {
      open: zusDialog.match('createRecordDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'sm',
    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
