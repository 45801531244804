import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box } from '../../../components/MuiGenerals';
import { useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import { IentitlementDetails, translateKeyObj as TK, translatePrefix } from './config';

export default function DialogEntitlementDetails() {
  const { t, tc } = useTranslation(translatePrefix);

  const zusDialog = useZusDialogStore();

  const entitlementDetails = zusDialog.meta?.entitlementDetails?.[0] as
    | IentitlementDetails
    | undefined;

  const handleCloseDialog = async () => {
    await zusDialog.close();
  };

  const labelElePairArr: [string, JSX.Element][] = [
    [TK.totalEntitlement, <Box>{entitlementDetails?.MemberEntitlements?.Total}</Box>],
    [TK.pending, <Box>{entitlementDetails?.MemberEntitlements?.Pending}</Box>],
    [TK.used, <Box>{entitlementDetails?.MemberEntitlements?.Used}</Box>],
    [TK.remaining, <Box>{entitlementDetails?.MemberEntitlements?.Remaining}</Box>],
  ];

  const dialogConfig: IdialogInOneProps = {
    size: 'sm',
    title: t(TK.entitilementDetails),
    self: {
      open: zusDialog.match('entitlementDetailsDialog'),
      onClose: handleCloseDialog,
    },
    content: <GridBox labelElePairArr={labelElePairArr} columnCount={1} />,
    onConfirm: () => {},
    onCancel: handleCloseDialog,
    isConfirmHidden: true,
    cancelButtonText: tc('close'),
  };

  return <DialogInOne {...dialogConfig} />;
}
