import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { EnumCollinsonCustomerAccountEnrollmentStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/PriorityPass/index';

import { OpRedirect } from '../../../assets/icons';
import { LoadingDialog } from '../../../components';
import { ExportBtn, OpIconButton } from '../../../components/Button';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import { downloadFiles, getFullApiResponse, toDisplayTime } from '../../../utils';
import { dataGridColumnConfig, useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogEnrolmentDetails from './DialogEnrolmentDetails';
import DialogEntitlementDetails from './DialogEntitlementDetails';
import DialogVisitHistory from './DialogVisitHistory';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const { membershipNumber } = params.row['rawData'] as ItableApiRes;

    const handleEntitlementDetailsBtn = async () => {
      let entitlementDetails = undefined;

      entitlementDetails = await api.entitlementDetails({ membershipNumber });

      if (!entitlementDetails) {
        return;
      }

      zusDialog.open('entitlementDetailsDialog', {
        entitlementDetails,
      });
    };
    const handleVisitHistoryBtn = async () => {
      let visitHistory = undefined;

      visitHistory = await api.visitHistory({ membershipNumber });

      if (!visitHistory) {
        return;
      }

      zusDialog.open('visitHistoryDialog', {
        visitHistory,
      });
    };

    return (
      <ButtonMenu
        title={t(TK.operation)}
        options={[
          {
            name: t(TK.entitilementDetails),
            onClickFn: handleEntitlementDetailsBtn,
            noShow: !hasPermission(PermissionKey.EntitlementDetails),
          },
          {
            name: t(TK.visitHistory),
            onClickFn: handleVisitHistoryBtn,
            noShow: !hasPermission(PermissionKey.VisitHistory),
          },
        ]}
      />
    );
  };

  const renderEnrolmentDetailsCell = (params: GridRenderCellParams<any, any, any>) => {
    const { enrollDetails: enrolmentDetails } = params.row['rawData'] as ItableApiRes;

    return (
      <OpIconButton
        size={'1.2rem'}
        title={''}
        svgUrl={OpRedirect}
        onClick={() => zusDialog.open('enrolmentDetailsDialog', { enrolmentDetails })}
      />
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      renderCell: renderOperationCell,
    }),
    useGenGridCol(TK.membershipNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.panLast4),
    useGenGridCol(TK.status),
    useGenGridCol(TK.enrolmentDetails, {
      minWidth: 150,
      renderCell: renderEnrolmentDetailsCell,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const hasOperationColPermission =
    hasPermission(PermissionKey.EntitlementDetails) || hasPermission(PermissionKey.VisitHistory);

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasOperationColPermission,
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogEnrolmentDetails />
      <DialogEntitlementDetails />
      <DialogVisitHistory />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
        initialState={dataGridColumnConfig(visibilityConfigArr)}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tb } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const mappedResult = [
        ['rawData', item],
        [TK.membershipNumber, item.membershipNumber],
        [TK.programName, item.programName],
        [TK.customerNumber, item.customerNumber],
        [TK.cardNumber, item.cardNumber],
        [TK.panLast4, item.panLast4],
        [TK.status, t(EnumCollinsonCustomerAccountEnrollmentStatus[item.status])],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
